import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// pricePlan
import RecommendedIcon from './assets/recommendedIcon.png';

const EasyAccessIcon = './assets/accessIcon.png';
const TaxIcon = './assets/receiveTax.png';
const OfferIcon = './assets/offIcon.png';

const Distance = '../../../assets/img/Distance.png';
const Typeofjob = '../../../assets/img/Typeofjob.png';
const DispatchFlatRates = '../../../assets/img/DispatchFlatRates.png';

const data = [
  {
    id: 1,
    icon: (
      <StaticImage
        src={Typeofjob}
        placeholder="blurred"
        loading="eager"
        alt="Type of job"
      />
    ),
    buttonText: 'Type of job'
  },
  {
    id: 2,
    icon: (
      <StaticImage
        src={Distance}
        placeholder="blurred"
        loading="eager"
        alt="Distance"
      />
    ),
    buttonText: 'Distance'
  },
  {
    id: 3,
    icon: (
      <StaticImage
        src={DispatchFlatRates}
        placeholder="blurred"
        loading="eager"
        alt="Dispatch flat rates"
      />
    ),
    buttonText: 'Dispatch Flat Rates'
  }
];

const pricingCards = [
  {
    header: 'RM115',
    type: 'Lite',
    description: 'Get Started',
    details: [
      ['RM100', 'Credits'],
      ['7 Jobs', 'Estimated number of jobs'],
      ['10 Hours', 'Hours of Part Timer jobs']
      // ['1 Job Listing', 'Number of GoGet Recuit listings']
    ],
    advantage: {
      icon: (
        <StaticImage
          src={OfferIcon}
          style={{ width: '120px' }}
          placeholder="blurred"
          alt="Free Credits"
        />
      ),
      text: 'Get up to 2% free credits with your first top up.'
    },
    publicId: '1db650d9'
  },
  {
    recommended: true,
    icon: RecommendedIcon,
    header: 'RM345',
    type: 'Essential Pack',
    description: 'Get reliable help for your business',
    details: [
      ['RM300', 'Credits'],
      ['20 Jobs', 'Estimated number of jobs'],
      ['30 Hours', 'Hours of Part Timer jobs'],
      ['1 Job Listing', 'Number of GoGet Recuit listings']
    ],
    advantage: {
      icon: (
        <StaticImage
          src={EasyAccessIcon}
          style={{ width: '120px' }}
          placeholder="blurred"
          alt="Management Fee"
        />
      ),
      text: 'A simple 15% management fee gives you access to all features.'
    },
    publicId: 'aef7161d'
  },
  {
    header: 'RM1150',
    type: 'Growing Business Pack',
    description: 'Scale your business with GoGetters',
    details: [
      ['RM1000', 'Credits'],
      [
        <div>
          {' '}
          RM20 <span className="teal"> FREE </span>{' '}
        </div>,
        '*First top up only'
      ],
      ['67 Jobs', 'Estimated number of jobs'],
      ['100 Hours', 'Hours of Part Timer jobs'],
      ['5 Job Listings', 'Number of GoGet Recuit listings']
    ],
    advantage: {
      icon: (
        <StaticImage
          src={TaxIcon}
          style={{ width: '120px' }}
          placeholder="blurred"
          alt="Tax Invoices"
        />
      ),
      text: 'Receive tax invoices for credit purchases.'
    },
    publicId: 'd4ff10e0'
  }
];

export { data, pricingCards };
